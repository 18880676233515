import React, { useContext } from "react";
import { RestaurantContext } from "../../Context/Restaurant";
import "./style.scss";

const LanguagesSwitcher = () => {
  const { setLanguage, language, setCurrency, currency, restaurant, isMultiLanguage, isMultiCurrency } = useContext(RestaurantContext);
  
  const changeLanguage = () => {
    if (language === "en") {
      setLanguage("ar");
    } else {
      setLanguage("en");
    }
  };

  const changeCurrency = () => {
    var currentCurrency = currency;
    if (!currentCurrency) {
      currentCurrency = restaurant.display_currency;
    }
    
    if (currentCurrency.Id === restaurant.display_currency.Id) {
      var itemCurrency = restaurant.categories[0]?.items[0]?.currency;
      if (itemCurrency) {
        setCurrency(itemCurrency);
      }
    } else {
      setCurrency(restaurant.display_currency);
    }
  };

  return (
    <div className='languages-container'>
      {isMultiCurrency && <svg className='icon cash ionicon' onClick={changeCurrency} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Cash</title><path d="M448 400H64a16 16 0 010-32h384a16 16 0 010 32zM416 448H96a16 16 0 010-32h320a16 16 0 010 32zM32 272H16v48a32 32 0 0032 32h48v-16a64.07 64.07 0 00-64-64z"/><path d="M480 240h16v-64h-16a96.11 96.11 0 01-96-96V64H128v16a96.11 96.11 0 01-96 96H16v64h16a96.11 96.11 0 0196 96v16h256v-16a96.11 96.11 0 0196-96zm-224 64a96 96 0 1196-96 96.11 96.11 0 01-96 96z"/><circle cx="256" cy="208" r="64"/><path d="M416 336v16h48a32 32 0 0032-32v-48h-16a64.07 64.07 0 00-64 64zM480 144h16V96a32 32 0 00-32-32h-48v16a64.07 64.07 0 0064 64zM96 80V64H48a32 32 0 00-32 32v48h16a64.07 64.07 0 0064-64z"/></svg>}
      {isMultiLanguage && <svg className='icon language ionicon' onClick={changeLanguage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Language</title><path d="M478.33 433.6l-90-218a22 22 0 00-40.67 0l-90 218a22 22 0 1040.67 16.79L316.66 406h102.67l18.33 44.39A22 22 0 00458 464a22 22 0 0020.32-30.4zM334.83 362L368 281.65 401.17 362zM267.84 342.92a22 22 0 00-4.89-30.7c-.2-.15-15-11.13-36.49-34.73 39.65-53.68 62.11-114.75 71.27-143.49H330a22 22 0 000-44H214V70a22 22 0 00-44 0v20H54a22 22 0 000 44h197.25c-9.52 26.95-27.05 69.5-53.79 108.36-31.41-41.68-43.08-68.65-43.17-68.87a22 22 0 00-40.58 17c.58 1.38 14.55 34.23 52.86 83.93.92 1.19 1.83 2.35 2.74 3.51-39.24 44.35-77.74 71.86-93.85 80.74a22 22 0 1021.07 38.63c2.16-1.18 48.6-26.89 101.63-85.59 22.52 24.08 38 35.44 38.93 36.1a22 22 0 0030.75-4.9z"/></svg>}
    </div>
  );
};

export default LanguagesSwitcher;
