import { CartFeatureName, MultiCurrencyFeatureName, MultiLanguageFeatureName } from "../config/constants";

const arabicFormatter = new Intl.NumberFormat("ar-qa", {
  useGrouping: true,
  maximumFractionDigits: 2,
});

const formatter = new Intl.NumberFormat("en", {
  useGrouping: true,
  maximumFractionDigits: 2,
});

export const toNumber = (number) => formatter.format(number);
export const toArabicNumber = (number) => arabicFormatter.format(number);

export const getMultiLanguageFeatureEnabled = (features) => {
  return features?.some(
    (x) => x.Name.toLowerCase() === MultiLanguageFeatureName.toLowerCase()
  );
};

export const getMultiCurrencyFeatureEnabled = (features) => {
  return features?.some(
    (x) => x.Name.toLowerCase() === MultiCurrencyFeatureName.toLowerCase()
  );
};

export const getCartFeatureEnabled = (features) => {
  return features?.some(
    (x) => x.Name.toLowerCase() === CartFeatureName.toLowerCase()
  );
};
