import React from 'react';
import './Header.scss';
import { RestaurantContext } from '../../Context/Restaurant';
import { useContext } from 'react';
import CategoriesSlider from '../CategoriesSlider';
import LanguagesSwitcher from '../LanguagesSwitcher';

const Header = ({ data }) => {
  const { language } = useContext(RestaurantContext);
  window.onscroll = function () {
    stickNavbar();
  };


  const stickNavbar = () => {
    if (window === undefined) return;

    var subHeader = document.getElementById('sub-header');
    var categoriesSlider = document.getElementById('categories-slider-1');

    if (window.scrollY > subHeader.clientHeight) {
      categoriesSlider.style.display = 'block';
      categoriesSlider.classList.add('sticky-categories');
    } else {
      categoriesSlider.style.display = 'none';
      categoriesSlider.classList.remove('sticky-categories');
    }
  };

  const gotoWebsite = () => {
    window.open(data.FriendlyUrl);
  };

  const call = () => {
    window.open(`tel:${data.PrimaryMobileNo}`);
  };

  return (
    <div className='header-component' id='header'>
      <div id='sub-header'>
        <div className='header'>
          <div className='menu-left'>
            {data.PrimaryMobileNo && (
              <svg
                onClick={call}
                className='icon call ionicon'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 512 512'
              >
                <title>Call</title>
                <path d='M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z' />
              </svg>
            )}
            {data.FriendlyUrl && (
              <svg
                onClick={gotoWebsite}
                className='icon website ionicon'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 512 512'
              >
                <title>Open</title>
                <path d='M224 304a16 16 0 01-11.31-27.31l157.94-157.94A55.7 55.7 0 00344 112H104a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h240a56.06 56.06 0 0056-56V168a55.7 55.7 0 00-6.75-26.63L235.31 299.31A15.92 15.92 0 01224 304z' />
                <path d='M448 48H336a16 16 0 000 32h73.37l-38.74 38.75a56.35 56.35 0 0122.62 22.62L432 102.63V176a16 16 0 0032 0V64a16 16 0 00-16-16z' />
              </svg>
            )}
          </div>
          <img
            src={`${process.env['REACT_APP_URL']}Media/${data?.LogoId}`}
            alt={`${data?.Name}`}
            className='logo'
          />
          <div className='menu-right'>
            <LanguagesSwitcher />
          </div>
        </div>
        <div className='title'>
          <p className='menu-title'>
            {language === 'en'
              ? `${data?.Description}`
              : `${data?.ArabicDescription}`}
          </p>
        </div>
      </div>
      <div id='categories-slider'>
        <CategoriesSlider categories={data?.categories} />
      </div>
      <div style={{ display: 'none' }} id='categories-slider-1'>
        <CategoriesSlider categories={data?.categories} />
      </div>
    </div>
  );
};

export default Header;
