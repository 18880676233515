import apiInstance from "../api";

export const getRestaurantDetailsByName = async (name) => {
  try {
    const result = await apiInstance.get(`Restaurants/${name}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};
