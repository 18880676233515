import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import CartState from "./Context/Cart/CartState";
import "./assets/animation.css";
import { RestaurantProvider } from "./Context/Restaurant";

ReactDOM.render(
  <RestaurantProvider>
    <CartState>
      <App />
    </CartState>
  </RestaurantProvider>,
  document.getElementById("root")
);
