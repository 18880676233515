import React, { useContext } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { RestaurantContext } from "../../Context/Restaurant";
import { toNumber, toArabicNumber } from "../../utils";
import "./style.scss";

const CartSummaryFooter = ({ toggleCartSlider }) => {
  const { restaurant, language } = useContext(RestaurantContext);
  const { total } = useContext(CartContext);

  return (
    <div
      className='cart-summary-footer-container'
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <h4 className='cart-total'>
        {language === "en" ? `Total` : `المجموع`}:{" "}
        {language === "en"
          ? toNumber(parseFloat(total))
          : toArabicNumber(parseFloat(total, 2))}{" "}
        {language === "en"
          ? restaurant?.display_currency?.Code
          : restaurant?.display_currency?.ArabicCode}
      </h4>
      <button
        type='button'
        className='view-cart-btn'
        onClick={toggleCartSlider}
      >
        {language === "en" ? `View Order` : `تحقق من الطلب`}
      </button>
    </div>
  );
};

export default CartSummaryFooter;
