import React, { useState } from 'react';
import CartSummaryFooter from '../components/CartSummaryFooter';
import CartSlider from '../components/CartSlider';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useApi from '../hooks/useApi';
import { getRestaurantDetailsByName } from '../services/restaurants';
import Loader from '../components/Loader';
import Page404 from './Page404';
import { useContext } from 'react';
import { RestaurantContext } from '../Context/Restaurant';
import Category from '../components/Category';
import Header from '../components/Header/Header';
import Logo from '../assets/logo.png';

const Home = () => {
  const { name } = useParams();
  const { updateRestaurant, cartEnabled, selectedCategory } =
    useContext(RestaurantContext);
  const restaurantDetails = useApi(getRestaurantDetailsByName, name);
  const [openCartSlider, setOpenCartSlider] = useState(false);
  const [displayedCategories, setDisplayedCategories] = useState([]);

  useEffect(() => {
    if (!name) return;
    restaurantDetails.fetch(true, true);
    return () => {
      restaurantDetails.isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    setDisplayedCategories(
      restaurantDetails.response?.categories.filter((category) => {
        return !selectedCategory || category.Id === selectedCategory;
      })
    );
  }, [restaurantDetails.response, selectedCategory]);

  const updateColors = () => {
    if (!restaurantDetails.response?.style) return;
    const root = document.documentElement;
    root.style.setProperty(
      '--clr-primary',
      restaurantDetails.response?.style.PrimaryColor
    );
    root.style.setProperty(
      '--clr-secondary',
      restaurantDetails.response?.style.SecondaryColor
    );
    root.style.setProperty(
      '--clr-shadow-color',
      restaurantDetails.response?.style.ShadowColor
    );
    root.style.setProperty(
      '--clr-text-color',
      restaurantDetails.response?.style.TextColor
    );
    root.style.setProperty(
      '--clr-background-color',

      restaurantDetails.response?.style.BackgroundColor
    );
  };

  useEffect(() => {
    if (!restaurantDetails.response) return;
    updateRestaurant(restaurantDetails.response);
    updateColors();
    document.title = `${restaurantDetails.response?.Name} | ${restaurantDetails.response?.Description}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantDetails.response]);

  const toggleCartSlider = () => setOpenCartSlider(!openCartSlider);
  if (restaurantDetails.isLoading) return <Loader />;
  if (!restaurantDetails.isLoading && !restaurantDetails.response?.Id)
    return <Page404 />;

  return (
    <main>
      <section className='menu section'>
        <Header data={restaurantDetails?.response} />
        <div
          className={`section-center ${openCartSlider ? 'add-padding' : ''}`}
        >
          {displayedCategories?.map((category) => (
            <Category key={category.Id} category={category} />
          ))}
          <div className='footer'>
            <img
              src={Logo}
              title='Appletun Logo'
              alt='Appletun Logo'
              className='footer-logo'
            />
            <div className='footer-credit'>
              <span>
                Appletun by{' '}
                <a href='https://mixedmug.com' target={'_blank'}>
                  MixedMug
                </a>
              </span>
            </div>
          </div>
        </div>
        {!openCartSlider && cartEnabled && (
          <CartSummaryFooter toggleCartSlider={toggleCartSlider} />
        )}
        {cartEnabled && openCartSlider && (
          <CartSlider
            toggleCartSlider={toggleCartSlider}
            openCartSlider={openCartSlider}
          />
        )}
      </section>
    </main>
  );
};

export default Home;
