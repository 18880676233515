import React, { memo, useCallback, useContext } from "react";
import "./style.scss";
import CartContext from "../../Context/Cart/CartContext";
import Plus from "../../assets/icons/add-circle-outline.svg";
import Minus from "../../assets/icons/remove-circle-outline.svg";
import { RestaurantContext } from "../../Context/Restaurant";
import { toNumber, toArabicNumber } from "../../utils";

const CartItem = ({ product }) => {
  const { removeFromCart, increase, decrease } = useContext(CartContext);
  const { language, restaurant } = useContext(RestaurantContext);

  const increaseQuantity = useCallback(
    () => increase(product),
    [increase, product]
  );
  const decreaseQuantity = useCallback(() => {
    if (product.quantity === 1) {
      removeFromCart(product);
    } else decrease(product);
  }, [decrease, product, removeFromCart]);

  return (
    <div
      className='cart-item-container'
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <div>
        <h5 className='product-cart-name'>
          {language === "en" ? product.Name : product.ArabicName}
        </h5>
      </div>
      <div className='buttons-container'>
        <div className='quantity-container'>
          <button onClick={decreaseQuantity} className='btn-icon'>
            <img src={Minus} alt={"Minus"} className='icon' />
          </button>
          <div>
            <p className='qty'>
              {language === "en"
                ? toNumber(parseFloat(product.quantity))
                : toArabicNumber(parseFloat(product.quantity))}
            </p>
          </div>
          <button onClick={increaseQuantity} className='btn-icon'>
            <img src={Plus} alt={"Plus"} className='icon' />
          </button>
        </div>
        <div className='price-container'>
          <p className='price'>
            {language === "en"
              ? toNumber(parseFloat(product.display_price * product.quantity))
              : toArabicNumber(
                  parseFloat(product.display_price * product.quantity)
                )}{" "}
            {language === "en"
              ? restaurant?.display_currency?.Code
              : restaurant?.display_currency?.ArabicCode}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(CartItem);
