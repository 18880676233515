import React, { useContext } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { RestaurantContext } from "../../Context/Restaurant";
import { toNumber, toArabicNumber } from "../../utils";
import "./style.scss";

const ProductCard = ({ product }) => {
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);
  const { language, cartEnabled, restaurant, currency } = useContext(RestaurantContext);

  const isInCart = () => !!cartItems.find((item) => item.Id === product.Id);

  const addRemoveItem = () => {
    if (!cartEnabled) return;
    isInCart() ? removeFromCart(product) : addToCart(product);
  };

  return (
    <article
      key={product.Id}
      className={`menu-item`}
      dir={language === "ar" ? "rtl" : "ltr"}
    >
      {product.ImageId && (
        <img
          src={`${process.env["REACT_APP_URL"]}Media/${product.ImageId}`}
          alt={product.Name}
          className='photo'
        />
      )}
      <div
        className={`item-info ${isInCart() ? "item-info-active" : ""}`}
        onClick={addRemoveItem}
      >
        <header>
          <h3 className='product-name'>
            {language === "en" ? product.Name : product.ArabicName}
          </h3>
          <h3 className='price'>
            {language === "en"
              ? toNumber(parseFloat(currency.Id == restaurant.DisplayCurrencyId ? product.display_price : product.Price))
              : toArabicNumber(parseFloat(currency.Id == restaurant.DisplayCurrencyId ? product.display_price : product.Price))}{" "}
            {language === "en"
              ? currency?.Code
              : currency?.ArabicCode}
          </h3>
        </header>
        {(product.Description || product.ArabicDescription) &&
          <p className='item-text'>
            {language === "en" ? product.Description : product.ArabicDescription}
          </p>
        }
        {(product.ExtraDescription || product.ArabicExtraDescription) &&
          <p className='item-text-extra'>
            {language === "en"
              ? product.ExtraDescription
              : product.ArabicExtraDescription}
          </p>
        }
      </div>
    </article>
  );
};

export default ProductCard;
