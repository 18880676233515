import React, { useContext } from "react";
import CartContext from "../../Context/Cart/CartContext";
import { RestaurantContext } from "../../Context/Restaurant";
import Animate from "../Animate";
import CartItem from "../CartItem";
import CartSummary from "./CartSummary";
import "./style.scss";

const CartSlider = ({ openCartSlider, toggleCartSlider }) => {
  const { language } = useContext(RestaurantContext);
  const { cartItems } = useContext(CartContext);
  return (
    <Animate
      animation={"fadeUp"}
      enter={openCartSlider}
      options={{ duration: 800 }}
      className='cart-slider-modal'
      dir={language === "en" ? "ltr" : "rtl"}
    >
      <CartSummary toggleCartSlider={toggleCartSlider} />
      <div className='inner'>
        {cartItems.length === 0 ? (
          <h4 style={{}}>
            {language === "en" ? "Cart is empty" : "السلة فارغة"}
          </h4>
        ) : (
          <div>
            {cartItems.map((product) => (
              <CartItem key={product.Id} product={product} />
            ))}
          </div>
        )}
      </div>
    </Animate>
  );
};

export default CartSlider;
