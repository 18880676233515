import React, { useContext, useEffect } from 'react';
import { RestaurantContext } from '../../Context/Restaurant';
import './style.scss';
import ProductCard from '../ProductCard';

const Category = ({ category }) => {
  const { language, selectedCategory } = useContext(RestaurantContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [selectedCategory]);

  return (
    <div id={category.Id} dir={language === 'ar' ? 'rtl' : 'ltr'}>
      <h2 className='category-title'>
        {language === 'en' ? category.Name : category.ArabicName}
      </h2>
      {category.items.map((item) => (
        <ProductCard key={item.Id} product={item} />
      ))}
    </div>
  );
};
export default Category;
