import React, { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { RestaurantContext } from '../../Context/Restaurant';
import './style.scss';
const CategoriesSlider = ({ categories }) => {
  const { language, selectedCategory, setSelectedCategory } =
    useContext(RestaurantContext);
  const [_categories, _setCategories] = useState();
  const handleClick = (categoryId, index) => {
    setSelectedCategory(categoryId);
  };

  const allCategory = {
    Name: 'All',
    ArabicName: 'الكل',
    Id: 0,
    Order: 0,
    RestaurantId: 0,
    Items: [],
  };

  useEffect(() => {
    const allCategories = [allCategory, ...categories];
    _setCategories(allCategories);
  }, [categories]);

  return (
    <div
      className={`categories-btn-container`}
      dir={language === 'en' ? 'ltr' : 'rtl'}
    >
      {_categories?.map((category, index) => {
        return (
          <button
            type='button'
            className={`filter-btn ${
              selectedCategory === category.Id ? 'active' : ''
            }`}
            key={category.Id}
            onClick={() => handleClick(category.Id)}
          >
            {language === 'en' ? category.Name : category.ArabicName}
          </button>
        );
      })}
    </div>
  );
};

export default CategoriesSlider;
