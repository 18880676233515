import React, { createContext, useState } from "react";
import { useEffect } from "react";
import {
  getCartFeatureEnabled,
  getMultiCurrencyFeatureEnabled,
  getMultiLanguageFeatureEnabled,
} from "../../utils";

export const RestaurantContext = createContext();

export const RestaurantProvider = (props) => {
  const [restaurant, setRestaurant] = useState({});
  const [isMultiLanguage, setIsMultiLanguage] = useState(false);
  const [isMultiCurrency, setIsMultiCurrency] = useState(false);
  const [cartEnabled, setIsCartEnabled] = useState(false);
  const [language, setLanguage] = useState();
  const [currency, setCurrency] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();

  const updateRestaurant = (newRestaurant) => {
    setLanguage(newRestaurant.DefaultLanguage);
    setRestaurant(newRestaurant);
    setCurrency(newRestaurant.display_currency);
  };

  // useEffect(() => {
  //   console.log('setting language', language);
  //   localStorage.setItem('defaultLang', language);
  // }, [language]);

  useEffect(() => {
    // const defaultLang = localStorage.getItem('defaultLang');
    // const restoLang = restaurant.DefaultLanguage
    //   ? restaurant.DefaultLanguage
    //   : 'en';

    // setLanguage(
    //   defaultLang || defaultLang != 'undefined' ? defaultLang : restoLang
    // );
    setIsMultiLanguage(getMultiLanguageFeatureEnabled(restaurant?.features));
    setIsMultiCurrency(getMultiCurrencyFeatureEnabled(restaurant?.features));
    setIsCartEnabled(getCartFeatureEnabled(restaurant?.features));
  }, [restaurant]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        updateRestaurant,
        language,
        setLanguage,
        currency,
        setCurrency,
        isMultiLanguage,
        isMultiCurrency,
        cartEnabled,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {props.children}
    </RestaurantContext.Provider>
  );
};
